import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

const tw = resolveConfig(tailwindConfig)

export default {
  init() {
    this.setNavStatus()

    window.addEventListener('resize', () => {
      this.setNavStatus()
    })
  },

  active: null,

  stuck: false,

  open: false,

  get toggleable () {
    return window.matchMedia('(min-width: ' + tw.theme.screens.lg + ')').matches
  },

  toggleNav() {
    this.open = ! this.open
  },

  setNavStatus() {
    this.open = window.matchMedia('(min-width: ' + tw.theme.screens.lg + ')').matches
  }
}