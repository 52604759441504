import './_bootstrap';

import header from './alpine/_header'
import timeline from './alpine/_timeline'

import '../css/app.css'

window.Alpine.store('header', header);
window.Alpine.data('timeline', timeline)
 
window.Alpine.start()