const plugin = require('tailwindcss/plugin')
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/assets/**/*.js",
    "./resources/blocks/**/src/*.js",
    "./resources/views/**/*.php"
  ],
  theme: {
    colors: {
      white: {
        DEFAULT: '#FFFFFF',
        eggshell: '#FDF6F4',
        soft: '#FFF9E2'
      },
      black: '#000000',
      gray: {
        800: '#333333',
        600: '#666666',
        400: '#999999',
        200: '#CCCCCC',
      },
      red: '#D71E46',
      orange: {
        DEFAULT: '#F7964A',
        soft: '#F99135',
        dark: '#D75E00'
      },
      pink: '#EF4961',
      yellow: {
        soft: '#FEEFAF',
        bright: '#F3ED70'
      },
      current: 'currentColor',
      transparent: 'transparent'
    },
    fontFamily: {
      'sans': ['fira-sans', ...defaultTheme.fontFamily.sans],
      'serif': ['calluna', ...defaultTheme.fontFamily.serif]
    },
    extend: {
      borderWidth: {
        '3': 'max(3px, 0.20833333vw)'
      },
      spacing: {
        gutter: 'max(2rem, 5.5vw)'
      },
      backgroundImage: {
        'selection': 'var(--tw-image-selection)',
        'bubble': 'url(@/assets/images/bubble-bg.svg)'
      }
    },
  },
  plugins: [],
}
