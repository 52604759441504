import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'
import { create } from "@lottiefiles/lottie-interactivity"

const tw = resolveConfig(tailwindConfig)

export default () => ({
  init() {
    let lgVisibility = [0, 0.5];

    if (window.matchMedia('(min-width: ' + tw.theme.screens['xl'] + ')').matches) {
      lgVisibility = [0.05, 0.7];
    }

    this.$nextTick(() => {
      if (window.matchMedia('(min-width: ' + tw.theme.screens.lg + ')').matches) {
        create({
          player: this.$refs['timeline-animation'],
          mode: 'scroll',
          actions: [
            {
              visibility: lgVisibility,
              type: 'seek',
              frames: [0, 260]
            }
          ]
        });
      } else {
        create({
          player: this.$refs['timeline-mobile'],
          mode: 'scroll',
          actions: [
            {
              visibility: [0.08, 0.7],
              type: 'seek',
              frames: [0, 255]
            }
          ]
        });
      }
    })
  }
})